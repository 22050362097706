import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Grid, IconButton, LinearProgress } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import BookingDialog from '../../Components/Dialog/BookingDialog';
import { SiteContext } from '../../Contexts/SiteContext';
import useContainerSize from '../../Hooks/UseContainerSize';
import { Event, GroupEvents, filterEventsByDate } from '../../Model/Event';
import { useGetEventsQuery } from '../../Service/Events';
import { today } from '../../Util/Dates';
import MultiDaySlots from './MultiDaySlots';
import './SlotsPager.css';

const daySlotSize = 140;

const calculateNumCols = (windowSize: number) => {
  const calculated = Math.floor((windowSize - daySlotSize) / daySlotSize);
  return Math.max(1, calculated);
};

const SlotsPager = () => {
  const { width } = useContainerSize('slots-view-container');
  const numCols = useMemo(() => calculateNumCols(width), [width]);
  const [openBookingDialog, setOpenBookingDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();

  const [slotsState, setSlotsState] = useState({
    date: today(),
    movedNext: true,
  });

  const handlePrevious = () => {
    setSlotsState((prev) => {
      return {
        movedNext: false,
        date: prev.date.minus({ days: numCols }),
      };
    });
  };

  const handleNext = () => {
    setSlotsState((prev) => {
      return {
        movedNext: true,
        date: prev.date.plus({ days: numCols }),
      };
    });
  };

  const siteID = useContext(SiteContext).site.id;

  const startDate = slotsState.date;
  const endDate = slotsState.date.plus({ days: numCols });

  const {
    data: events,
    isLoading: isEventsLoading,
    isSuccess,
  } = useGetEventsQuery({
    startMillis: startDate.toMillis(),
    endMillis: endDate.toMillis(),
    siteID: siteID,
  });
  const filtered = filterEventsByDate(
    // We map the events because they come in as read-only from RTK
    GroupEvents(events?.map((e) => e) || []),
    startDate,
    endDate
  );

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  return (
    <div {...handlers}>
      {isEventsLoading && <LinearProgress />}
      {isSuccess && (
        <Grid
          id="slots-view-container"
          container
          marginTop={2}
          justifyContent={'center'}
        >
          <Grid item marginTop={1}>
            <IconButton
              onClick={handlePrevious}
              disabled={slotsState.date <= today()}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </Grid>
          <Grid item>
            <MultiDaySlots
              handleClick={(event) => {
                setSelectedEvent(event);
                setOpenBookingDialog(true);
              }}
              key={slotsState.date.toISO()}
              slots={filtered}
            />
          </Grid>
          <Grid item marginTop={1}>
            <IconButton onClick={handleNext}>
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <BookingDialog
        open={openBookingDialog}
        setOpen={setOpenBookingDialog}
        event={selectedEvent}
      />
    </div>
  );
};

export default SlotsPager;
