import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { ResourceHeaderProps } from 'react-big-calendar';
import {
  HasSiteBookingAdminPerms,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { Court } from '../../Model/Court';
import { BlockCourtDialog } from './BlockCourtDialog';

export const CourtHeader = (props: ResourceHeaderProps<Court>) => {
  const permsCtx = useContext(PermsContext);
  const siteCtx = useContext(SiteContext);

  const [dialogOpen, setDialogOpen] = useState(false);

  const isPermitted = HasSiteBookingAdminPerms(permsCtx.perms, siteCtx.site.id);

  function handleShowDialog(): void {
    setDialogOpen(true);
  }

  return (
    <>
      {isPermitted && <Button onClick={handleShowDialog}>{props.label}</Button>}
      {!isPermitted && <>{props.label}</>}
      <BlockCourtDialog
        open={dialogOpen}
        court={props.resource}
        setOpen={setDialogOpen}
      ></BlockCourtDialog>
    </>
  );
};
