import { Delete, Edit, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment, SyntheticEvent, useContext, useState } from 'react';
import { ConfirmDialog } from '../../Components/Dialog/ConfirmDialog';
import {
  HasSitePerm,
  PermDelSiteCourt,
  PermEditSiteCourt,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { isExpanded, setExpanded } from '../../Contexts/UIState';
import { Court } from '../../Model/Court';
import { useDeleteCourtMutation } from '../../Service/Courts';
import { getErrorMessage } from '../../Util/Error';
import { AddEditCourtDialog } from './AddEditCourtDialog';
import { ManageCourtSlots } from './Slots/ManageCourtSlots';

type ViewCourtProps = {
  court: Court;
  onDelete?: (id: number) => void;
  onSave?: (court: Court) => void;
};
export const CourtAccordion = (props: ViewCourtProps) => {
  const { perms: permsState } = useContext(PermsContext);

  const [openDeleteCourtConfirm, setDeleteCourtOpenConfirm] = useState(false);
  const [deletionError, setDeletionError] = useState<string>();

  const siteId = props.court.SiteID;

  const [deleteCourt] = useDeleteCourtMutation();

  async function handleDeleteConfirm(): Promise<void> {
    try {
      await deleteCourt(props.court);
    } catch (e) {
      const msg = getErrorMessage(e);
      setDeletionError('Error deleting court. ' + msg);
    }
  }

  function handleOpenDeleteDialog(): void {
    setDeleteCourtOpenConfirm(true);
  }

  const [openEditCourtDialog, setOpenEditCourtDialog] = useState(false);
  function handleOpenEditDialog(): void {
    setOpenEditCourtDialog(true);
  }

  function handleCourtSaved(court: Court): void {
    if (props.onSave) {
      props.onSave(court);
    }
  }

  const expansionKey = 'court-' + props.court.ID;
  const expanded = isExpanded(expansionKey);

  function handleOnExpansionChange(
    _event: SyntheticEvent<Element, Event>,
    expanded: boolean
  ): void {
    setExpanded(expansionKey, expanded);
  }

  // TODO: Use single instance for Dialogs instead of one per accordion
  return (
    <Fragment>
      {deletionError && (
        <Alert
          severity="error"
          onClose={() => {
            setDeletionError(undefined);
          }}
        >
          {deletionError}
        </Alert>
      )}
      <Accordion defaultExpanded={expanded} onChange={handleOnExpansionChange}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography>{props.court.Name}</Typography>
          </Stack>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <Typography>{props.court.Description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{props.court.Location}</Typography>
            </Grid>
            {(props.court.Location || props.court.Description) && (
              <Grid item py={1} xs={12}>
                <Divider />
              </Grid>
            )}
            <Grid item xs={12}>
              <ManageCourtSlots court={props.court} />
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          {HasSitePerm(permsState, siteId, PermEditSiteCourt) && (
            <Button onClick={handleOpenEditDialog} startIcon={<Edit />}>
              Edit
            </Button>
          )}
          {HasSitePerm(permsState, siteId, PermDelSiteCourt) && (
            <Button
              onClick={handleOpenDeleteDialog}
              startIcon={<Delete />}
              color="error"
            >
              Delete
            </Button>
          )}
        </AccordionActions>
      </Accordion>
      <ConfirmDialog
        open={openDeleteCourtConfirm}
        setOpen={setDeleteCourtOpenConfirm}
        title="Confirm Delete"
        confirmText="Delete"
        danger
        message="Are you sure you want to delete this court? All related slots and bookings will be deleted as well!"
        onConfirm={handleDeleteConfirm}
      />
      <AddEditCourtDialog
        open={openEditCourtDialog}
        title="Edit Court"
        siteId={props.court.SiteID}
        court={props.court}
        setOpen={setOpenEditCourtDialog}
        onCourtSaved={handleCourtSaved}
      />
    </Fragment>
  );
};
