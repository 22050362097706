import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { Court } from '../../Model/Court';

import { yupResolver } from '@hookform/resolvers/yup';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useContext, useEffect, useMemo } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { SiteContext } from '../../Contexts/SiteContext';
import { useGetEventTypesQuery } from '../../Service/EventTypes';

type Props = {
  open: boolean;
  court: Court;
  setOpen: (open: boolean) => void;
};
export const BlockCourtDialog = (props: Props) => {
  const siteID = useContext(SiteContext).site.id;
  const { data: eventTypes } = useGetEventTypesQuery(siteID);

  const eventTypeIDs = useMemo(() => {
    return eventTypes?.map((eventType) => eventType.ID) || [0];
  }, [eventTypes]);

  const minStartTime = DateTime.now();
  const schema = Yup.object().shape({
    StartTime: Yup.date()
      .min(minStartTime.toJSDate(), 'Start Time must be in the future')
      .required('Start Time is required'),
    EndTime: Yup.date()
      .min(Yup.ref('StartTime'), 'End Time must be after start time')
      .required('End Time is required'),
    RepeatUntil: Yup.date(),
    CancelBookings: Yup.boolean(),
    EventTypeID: Yup.number()
      .required('Event Type is required')
      .oneOf(eventTypeIDs, 'Invalid Event Type'),
  });

  const defaultValues = useMemo(() => {
    return {
      StartTime: minStartTime.toJSDate(),
      EndTime: minStartTime.plus({ hours: 1 }).toJSDate(),
      RepeatUntil: minStartTime.plus({ days: 1 }).toJSDate(),
      CancelBookings: false,
      EventTypeID: eventTypeIDs.length > 0 ? eventTypeIDs[0] : 0,
    };
  }, [minStartTime, eventTypeIDs]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props.open) {
      reset(defaultValues);
    }
  }, [props.open, reset, defaultValues]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    console.debug('Block Court Data', data);

    props.setOpen(false);
  };

  return (
    <Dialog
      aria-label="Block Court Dialog"
      maxWidth="xs"
      fullWidth
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <DialogTitle textAlign="center">Block {props.court.Name}</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2} display="flex" justifyContent="center">
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.StartTime}>
                <Controller
                  name="StartTime"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DateTimePicker
                      label="Start Time"
                      value={DateTime.fromJSDate(value)}
                      onChange={(date) => onChange(date?.toJSDate() || null)}
                      onAccept={(date) => onChange(date?.toJSDate() || null)}
                      inputRef={ref}
                    />
                  )}
                />
                <FormHelperText>{errors.StartTime?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.EndTime}>
                <Controller
                  name="EndTime"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DateTimePicker
                      label="End Time"
                      value={DateTime.fromJSDate(value)}
                      onChange={(date) => onChange(date?.toJSDate() || null)}
                      onAccept={(date) => onChange(date?.toJSDate() || null)}
                      inputRef={ref}
                    />
                  )}
                />
                <FormHelperText>{errors.EndTime?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.RepeatUntil}>
                <Controller
                  name="RepeatUntil"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DateTimePicker
                      label="Repeat Until"
                      value={value ? DateTime.fromJSDate(value) : null}
                      onChange={(date) => onChange(date?.toJSDate() || null)}
                      onAccept={(date) => onChange(date?.toJSDate() || null)}
                      inputRef={ref}
                    />
                  )}
                />
                <FormHelperText>{errors.RepeatUntil?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.EventTypeID}>
                <Controller
                  name="EventTypeID"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputLabel>Event Type</InputLabel>
                      <Select
                        {...field}
                        value={
                          eventTypeIDs.includes(field.value) ? field.value : ''
                        }
                        label="Event Type"
                        error={!!errors.EventTypeID}
                      >
                        {eventTypes?.map((eventType) => (
                          <MenuItem key={eventType.ID} value={eventType.ID}>
                            {eventType.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
                <FormHelperText>{errors.EventTypeID?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.CancelBookings}>
                <Controller
                  name="CancelBookings"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      control={<Switch />}
                      label="Cancel Existing Bookings"
                    />
                  )}
                />
                <FormHelperText>
                  {errors.CancelBookings?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography color="error" variant="subtitle1">
                {errors.root?.message}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
          <Button onClick={() => props.setOpen(false)}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
