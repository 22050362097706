import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { bookingRulesApi } from '../Service/BookingRules';
import { courtsApi } from '../Service/Courts';
import { eventsApi } from '../Service/Events';
import { eventTypesApi } from '../Service/EventTypes';
import { kbApi } from '../Service/KB';
import { meApi } from '../Service/Me';
import { memberTypesApi } from '../Service/MemberTypes';
import { siteApi } from '../Service/Site';
import { slotsApi } from '../Service/Slots';
import { slotTypesApi } from '../Service/SlotTypes';

export const appStore = configureStore({
  reducer: {
    [bookingRulesApi.reducerPath]: bookingRulesApi.reducer,
    [courtsApi.reducerPath]: courtsApi.reducer,
    [eventTypesApi.reducerPath]: eventTypesApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [kbApi.reducerPath]: kbApi.reducer,
    [memberTypesApi.reducerPath]: memberTypesApi.reducer,
    [slotTypesApi.reducerPath]: slotTypesApi.reducer,
    [slotsApi.reducerPath]: slotsApi.reducer,
    [meApi.reducerPath]: meApi.reducer,
    [siteApi.reducerPath]: siteApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(bookingRulesApi.middleware)
      .concat(courtsApi.middleware)
      .concat(eventTypesApi.middleware)
      .concat(eventsApi.middleware)
      .concat(kbApi.middleware)
      .concat(memberTypesApi.middleware)
      .concat(slotTypesApi.middleware)
      .concat(slotsApi.middleware)
      .concat(siteApi.middleware)
      .concat(meApi.middleware),
});

setupListeners(appStore.dispatch);

export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
export type AppStore = typeof appStore;
