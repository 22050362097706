import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Event } from '../../Model/Event';
import { useCancelBookingMutation } from '../../Service/Events';
import { useGetMeQuery } from '../../Service/Me';
import { getErrorMessage } from '../../Util/Error';
export type Props = {
  event: Event;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export const EventDetailsDialog = (props: Props) => {
  const { data: me } = useGetMeQuery({});
  const isCancellable = me?.ID === props.event.Booking?.BookerID;

  const [cancelBooking, { isLoading: isCancelling }] =
    useCancelBookingMutation();

  const [error, setError] = useState<string>();

  const handleCancel = async () => {
    try {
      await cancelBooking(props.event).unwrap();
      props.setOpen(false);
    } catch (e) {
      const msg = getErrorMessage(e);
      setError(msg);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <DialogTitle textAlign={'center'}>Event Details</DialogTitle>
      <DialogContent>
        <Grid
          container
          display={'flex'}
          alignItems={'center'}
          columnSpacing={2}
          rowSpacing={1}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Title
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{props.event.Title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Start
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {DateTime.fromISO(props.event.Start || '').toFormat(
                'yyyy-MM-dd HH:mm'
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              End
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {DateTime.fromISO(props.event.End || '').toFormat(
                'yyyy-MM-dd HH:mm'
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Court
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{props.event.CourtName}</Typography>
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={2}>
            <Typography color={'error'} variant="subtitle1">
              {error}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isCancellable && (
          <LoadingButton
            loading={isCancelling}
            onClick={handleCancel}
            color="error"
          >
            Cancel
          </LoadingButton>
        )}
        <Button onClick={() => props.setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
