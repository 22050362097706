import { Grid, Stack, Typography } from '@mui/material';
import { Slot } from '../../../Model/Slot';
import { Weekday, WeekdayNames } from '../../../Model/Time/Weekday';
import { SlotChip } from './SlotChip';

type SlotsViewProps = {
  slots?: Slot[];
  onDeleteClicked?: (slot: Slot) => void;
};

type SlotDayProps = {
  slots?: Slot[];
  day: string;
  onDeleteClicked?: (slot: Slot) => void;
};

const SlotDay = (props: SlotDayProps) => {
  const { slots, day, onDeleteClicked } = props;
  if (!slots) {
    return null;
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body2">{day}</Typography>
      {slots.map((slot) => {
        return (
          <SlotChip
            onDeleteClicked={onDeleteClicked}
            key={slot.ID}
            slot={slot}
          />
        );
      })}
    </Stack>
  );
};

export const SlotsView = (props: SlotsViewProps) => {
  const { slots, onDeleteClicked } = props;
  if (!slots) {
    return null;
  }
  const groupedSlotsByDay = slots.reduce((acc: any, slot) => {
    acc[slot.Weekday] = acc[slot.Weekday] || [];
    acc[slot.Weekday].push(slot);
    return acc;
  }, {} as Record<Weekday, Slot[]>);

  return (
    <Grid
      container
      direction="row"
      display={'flex'}
      justifyContent={'space-between'}
      textAlign={'center'}
      spacing={2}
      columns={7}
    >
      {Object.values(WeekdayNames).map((weekday) => (
        <Grid item key={weekday}>
          <SlotDay
            onDeleteClicked={onDeleteClicked}
            slots={groupedSlotsByDay[weekday]}
            day={Weekday[weekday]}
          />
        </Grid>
      ))}
    </Grid>
  );
};
