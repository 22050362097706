import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  HasSiteBookingAdminPerms,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { BookingRequest } from '../../Model/Booking';
import {
  AddCostsToGameTypes,
  Event,
  GameTypeWithCost,
} from '../../Model/Event';
import { MemberType } from '../../Model/MemberType';
import { PlayerCost } from '../../Model/PlayerCost';
import { User } from '../../Model/User';
import { useBookSlotMutation } from '../../Service/Events';
import { useGetEventTypesQuery } from '../../Service/EventTypes';
import { useGetKBQuery } from '../../Service/KB';
import { useGetMeQuery } from '../../Service/Me';
import { useGetMemberTypesQuery } from '../../Service/MemberTypes';
import { useGetSiteQuery } from '../../Service/Site';
import { toApiUrl } from '../../Util/Api';
import { getErrorMessage } from '../../Util/Error';
import { UserSearchInput } from '../UI/UserSearchInput';
import { BookingDetails } from './BookingDetails';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event?: Event;
};

export type BookingInfo = {
  SelectedGameType: GameTypeWithCost;
  NumPlayers: number;
  SelectedPlayers: (User | null)[];
  AnonymousGuests: boolean[];
};

const formatCost = (
  playerCosts?: PlayerCost[],
  memberTypes?: MemberType[],
  playerID?: number
): JSX.Element => {
  if (!playerCosts) {
    return <></>;
  }

  const cost = playerCosts.find((p) => p.UserID === playerID);

  if (!cost) {
    return <></>;
  }

  let memberName: string | undefined = 'Guest';
  if (cost.MemberTypeID > 0) {
    memberName = memberTypes?.find((mt) => mt.ID === cost.MemberTypeID)?.Name;
  }

  if (!memberName) {
    return <Typography color="error">Failed to calculate cost</Typography>;
  }

  return (
    <Typography variant="body2" color={cost.Error ? 'error' : 'inherit'}>
      {memberName}: {cost.Error ? '' : '€'}{' '}
      {cost.Error ? cost.Error : cost.Cost}
    </Typography>
  );
};

const isValidBookingInfo = (
  info: BookingInfo,
  costs: PlayerCost[]
): boolean => {
  if (costs.some((p) => p.Error)) {
    return false;
  }

  for (let i = 0; i < info.SelectedPlayers.length; i++) {
    const player = info.SelectedPlayers[i];
    if (!player && !info.AnonymousGuests[i]) {
      return false;
    }
  }

  return true;
};

const dummyGameTypeWithCost: GameTypeWithCost = {
  GameType: {
    ID: 0,
    Name: '',
    Subtype: '',
    NumPlayers: 1,
    Description: '',
    AdminOnly: false,
  },
  Cost: 0,
  GuestAllowances: {
    FreeLeft: 0,
    PaidLeft: 0,
    Cost: 0,
  },
};

const BookingDialog = (props: Props) => {
  const { open, setOpen, event } = props;

  const { data: me } = useGetMeQuery({});
  const permsCtx = useContext(PermsContext);

  const { data: kb } = useGetKBQuery();

  const { data: eventTypes } = useGetEventTypesQuery(event?.SiteID as number);
  const gameTypes = useMemo(() => {
    return (
      kb?.GameTypes ?? [
        {
          ID: 0,
          Name: '',
          Subtype: '',
          NumPlayers: 0,
          Description: '',
          AdminOnly: false,
        },
      ]
    );
  }, [kb?.GameTypes]);

  const [eventType, setEventType] = useState<number>(0);

  const [gameTypesWithCost, setGameTypesWithCost] = useState<
    GameTypeWithCost[]
  >([dummyGameTypeWithCost]);
  useEffect(() => {
    setGameTypesWithCost(() => {
      return AddCostsToGameTypes(
        gameTypes,
        event?.GameTypeCosts,
        HasSiteBookingAdminPerms(permsCtx.perms, event?.SiteID as number)
      );
    });
  }, [gameTypes, event?.GameTypeCosts, event?.SiteID, permsCtx.perms]);

  const initialCost =
    gameTypesWithCost.length === 0
      ? dummyGameTypeWithCost
      : gameTypesWithCost[0];

  const reducer = (state: BookingInfo, action: any) => {
    const index = action.payload?.index;
    const anonymousGuests = state.AnonymousGuests;
    const selectedPlayers = state.SelectedPlayers;
    switch (action.type) {
      case 'clearSelectedPlayers':
        return {
          ...state,
          SelectedPlayers: Array(state.NumPlayers).fill(null),
          AnonymousGuest: Array(state.NumPlayers).fill(false),
        };
      case 'setAnonymousGuest':
        anonymousGuests[index] = action.payload.anonymousGuest;
        if (action.payload.anonymousGuest) {
          selectedPlayers[index] = null;
        }
        return {
          ...state,
          AnonymousGuests: anonymousGuests,
          SelectedPlayers: selectedPlayers,
        };
      case 'setSelectedPlayer':
        const user = action.payload.user;
        selectedPlayers[index] = user;
        anonymousGuests[index] = user ? false : true;
        return {
          ...state,
          SelectedPlayers: selectedPlayers,
          AnonymousGuests: anonymousGuests,
        };
      case 'updateSelectedGameType':
        const numPlayers = Math.max(0, action.payload.GameType.NumPlayers - 1);
        const newState = {
          SelectedGameType: action.payload,
          NumPlayers: numPlayers,
          SelectedPlayers: Array(numPlayers).fill(null),
          AnonymousGuests: Array(numPlayers).fill(false),
        } as BookingInfo;

        return newState;
      default:
        return state;
    }
  };

  let numPlayers = initialCost.GameType.NumPlayers;

  const [bookingInfo, dispatch] = useReducer(reducer, {
    SelectedGameType: initialCost,
    NumPlayers: numPlayers,
    SelectedPlayers: Array(numPlayers).fill(null),
    AnonymousGuests: Array(numPlayers).fill(false),
  });

  function handlePlayerSelection(i: number, v?: User | null) {
    dispatch({ type: 'setSelectedPlayer', payload: { index: i, user: v } });
  }

  function handleSetAnoynmousGuests(i: number, v: boolean) {
    dispatch({
      type: 'setAnonymousGuest',
      payload: { index: i, anonymousGuest: v },
    });
  }

  const courtID = event?.CourtID || -1;
  const slotID = event?.SlotID || -1;
  const siteID = event?.SiteID || -1;
  const { data: memberTypes } = useGetMemberTypesQuery(siteID);

  const { data: site } = useGetSiteQuery(siteID);

  const [serverError, setServerError] = useState<string | null>(null);

  const [playerCosts, setPlayerCosts] = useState<PlayerCost[]>([]);
  const handleCalculateCosts = useCallback(async (): Promise<void> => {
    const selectedPlayerIDs: number[] = bookingInfo.SelectedPlayers.map(
      (p) => p?.ID || -1
    );

    if (bookingInfo.SelectedGameType.GameType.NumPlayers > 0) {
      selectedPlayerIDs.push(me?.ID || -1);
    }

    const request: BookingRequest = {
      SlotID: slotID,
      GameTypeID: bookingInfo.SelectedGameType.GameType.ID,
      SlotDate: DateTime.fromISO(event?.Start || ''),
      ClaimedCost: 0,
      Players: selectedPlayerIDs,
      EventTypeID: eventType,
    };

    const path = `/sites/${siteID}/courts/${courtID}/slots/${slotID}/bookings/costs`;

    setServerError(null);
    console.debug('Calculate Costs', request);

    try {
      const costs = await axios.post<BookingInfo, AxiosResponse<PlayerCost[]>>(
        toApiUrl(path),
        request
      );

      console.log('Costs', costs.data);
      setPlayerCosts(costs.data);
    } catch (error) {
      const msg = getErrorMessage(error);
      setServerError(msg);
    }
  }, [
    eventType,
    bookingInfo.SelectedGameType.GameType.NumPlayers,
    bookingInfo.SelectedPlayers,
    bookingInfo.SelectedGameType.GameType.ID,
    courtID,
    siteID,
    slotID,
    event?.Start,
    me?.ID,
  ]);

  const [bookSlot, { isLoading: isBooking }] = useBookSlotMutation();

  async function handleSubmit(): Promise<void> {
    setServerError(null);
    const selectedPlayerIDs: number[] = bookingInfo.SelectedPlayers.map(
      (p) => p?.ID || -1
    );

    if (bookingInfo.SelectedGameType.GameType.NumPlayers > 0) {
      selectedPlayerIDs.push(me?.ID || -1);
    }

    const request: BookingRequest = {
      SlotID: slotID,
      GameTypeID: bookingInfo.SelectedGameType.GameType.ID,
      SlotDate: DateTime.fromISO(event?.Start || ''),
      ClaimedCost: playerCosts.reduce((a, b) => a + b.Cost, 0),
      Players: selectedPlayerIDs,
      EventTypeID: eventType,
    };

    console.debug('post booking', request);
    try {
      await bookSlot({ siteID, courtID, slotID, request }).unwrap();
      setOpen(false);
    } catch (e) {
      const errMessage = getErrorMessage(e);
      setServerError(errMessage);
    }
  }

  useEffect(() => {
    const numPlayers = bookingInfo.SelectedPlayers.filter(
      (p) => p !== null
    ).length;

    const numAnonymousGuests = bookingInfo.AnonymousGuests.filter(
      (p) => p === true
    );
    if (numPlayers === 0 && numAnonymousGuests.length === 0) {
      return;
    }
    handleCalculateCosts();
  }, [bookingInfo, handleCalculateCosts]);

  function handleCancel(): void {
    setOpen(false);
  }

  const handleGameChange = useCallback(
    (_event: any, value: number): void => {
      const gtCost = gameTypesWithCost.find((gt) => gt.GameType.ID === value)!;
      if (!gtCost) {
        return;
      }
      dispatch({ type: 'updateSelectedGameType', payload: gtCost });
    },
    [gameTypesWithCost, dispatch]
  );

  // Reset on opening
  useEffect(() => {
    if (open) {
      handleGameChange(null, initialCost.GameType.ID);
    } else {
      dispatch({ type: 'clearSelectedPlayers' });
    }
  }, [open, initialCost, handleGameChange]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const canBook = isValidBookingInfo(bookingInfo, playerCosts);
  return (
    <Dialog
      aria-labelledby="Book a Court"
      maxWidth={'xs'}
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle textAlign={'center'}>Book a Court</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ py: 2 }} alignItems={'center'}>
          <ToggleButtonGroup
            value={bookingInfo.SelectedGameType.GameType.ID}
            exclusive
            onChange={handleGameChange}
          >
            {gameTypesWithCost.map((gt) => (
              <ToggleButton
                key={gt.GameType.ID}
                value={gt.GameType.ID}
                color="primary"
              >
                {gt.GameType.Name}
                {gt.GameType.Subtype ? `\n${gt.GameType.Subtype}` : ''}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <BookingDetails bookingInfo={bookingInfo} event={event} me={me} />
          {HasSiteBookingAdminPerms(permsCtx.perms, siteID) && (
            <FormControl style={{ minWidth: 140 }}>
              <InputLabel>Event Type</InputLabel>
              <Select
                label="Event Type"
                value={eventType || ''}
                onChange={(e) => setEventType(Number(e.target.value))}
              >
                <MenuItem value="">None</MenuItem>
                {eventTypes?.map((et) => (
                  <MenuItem key={et.ID} value={et.ID}>
                    {et.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Grid
            container
            spacing={2}
            columns={12}
            display={'flex'}
            alignContent={'center'}
          >
            {bookingInfo.SelectedPlayers.map((p, i) => (
              <Fragment key={i}>
                <Grid item xs={8}>
                  <UserSearchInput
                    key={i}
                    siteId={siteID}
                    disabled={bookingInfo.AnonymousGuests[i] || false}
                    onChange={(p) => handlePlayerSelection(i, p)}
                    value={p}
                    label={'Player ' + (i + 2)}
                  />
                </Grid>
                <Grid item xs={4}>
                  {site?.AllowAnonymousGuest && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bookingInfo.AnonymousGuests[i] || false}
                          onChange={(e) =>
                            handleSetAnoynmousGuests(i, e.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Guest"
                    />
                  )}
                </Grid>

                {(bookingInfo.AnonymousGuests[i] ||
                  bookingInfo.SelectedPlayers[i]) && (
                  <Grid item xs={12}>
                    {formatCost(playerCosts, memberTypes, p?.ID || -1)}
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
          {serverError && (
            <Typography color={'error'}>{serverError}</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={isBooking}
          disabled={!canBook}
          onClick={handleSubmit}
          color="primary"
        >
          Book It!
        </LoadingButton>
        <Button color="inherit" onClick={handleCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDialog;
