import { Add } from '@mui/icons-material';
import {
  Divider,
  Fab,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import {
  HasSitePerm,
  PermAddSiteCourt,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { useGetCourtsQuery } from '../../Service/Courts';
import { AddEditCourtDialog } from './AddEditCourtDialog';
import { CourtAccordion } from './CourtAccordion';

export const ManageCourtsPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenCourtDialog = () => {
    setDialogOpen(true);
  };

  const { perms: permissions } = useContext(PermsContext);

  const siteCtx = useContext(SiteContext);

  const { data: courts, isLoading: courtsLoading } = useGetCourtsQuery(
    siteCtx.site.id
  );

  return (
    <Fragment>
      <Stack spacing={2}>
        <Stack spacing={3} alignItems="center" direction="row">
          {HasSitePerm(
            permissions,
            Number(siteCtx.site.id),
            PermAddSiteCourt
          ) && (
            <Tooltip title="Add Court">
              <Fab
                size="small"
                onClick={handleOpenCourtDialog}
                color="primary"
                aria-label="add"
              >
                <Add />
              </Fab>
            </Tooltip>
          )}
          <Typography variant="h6">Manage Courts</Typography>
        </Stack>
        <Divider />
        <Stack>
          {courtsLoading && <LinearProgress />}
          {!courtsLoading &&
            courts?.map((court) => (
              <CourtAccordion key={court.ID} court={court} />
            ))}
        </Stack>
      </Stack>
      <AddEditCourtDialog
        title="Add Court"
        siteId={siteCtx.site.id}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </Fragment>
  );
};
