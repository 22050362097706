import { Add } from '@mui/icons-material';
import {
  Divider,
  Fab,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import {
  HasSitePerm,
  PermAddSiteSlot,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { useGetSlotTypesQuery } from '../../Service/SlotTypes';
import { AddEditSlotTypeDialog } from './AddEditSlotTypeDialog';
import { SlotTypeAccordion } from './SlotTypeAccordion';

export const ManageSlotTypeRulesPage = () => {
  const siteID = useContext(SiteContext).site.id;

  const { perms } = useContext(PermsContext);

  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: slotTypes, isLoading: isSlotTypesLoading } =
    useGetSlotTypesQuery(siteID);

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack spacing={3} alignItems={'center'} direction="row">
          {HasSitePerm(perms, siteID, PermAddSiteSlot) && (
            <Tooltip title="Add Slot Type">
              <Fab
                size="small"
                onClick={() => setDialogOpen(true)}
                color="primary"
                aria-label="add"
              >
                <Add />
              </Fab>
            </Tooltip>
          )}
          <Typography variant="h6">Manage Slot Types</Typography>
        </Stack>
        <Divider />
        <Stack>
          {isSlotTypesLoading && <LinearProgress />}
          {!isSlotTypesLoading &&
            slotTypes?.map((slotType) => (
              <SlotTypeAccordion key={slotType.ID} slotType={slotType} />
            ))}
        </Stack>
      </Stack>
      <AddEditSlotTypeDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={'Add Slot Type'}
      />
    </>
  );
};
