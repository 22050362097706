import './Logo.css';
import { Box, ButtonBase, Stack } from '@mui/material';

const Logo = () => {
  return (
    <Stack direction={'row'} alignItems={'center'} mr={{ xs: 0, sm: 5 }}>
      <Box sx={{ mr: 1, ml: 1 }}>
        <ButtonBase>
          <img src="/images/logo-icon.png" alt="logo icon" />
        </ButtonBase>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <ButtonBase>
          <span className="court">Court</span>&nbsp;
          <span className="go">Go</span>
        </ButtonBase>
      </Box>
    </Stack>
  );
};

export default Logo;
