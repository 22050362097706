import { createApi } from '@reduxjs/toolkit/query/react';
import { MemberType } from '../Model/MemberType';
import { UserInfo } from '../Model/UserInfo';
import { UserRoles } from '../Model/UserRoles';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export type UpdateMeRequest = {
  FirstName: string;
  LastName: string;
  StreetAddress: string;
  City: string;
  Province: string;
  PostalCode: string;
  PhoneNumber: string;
  CountryID: number;
  Avatar: string;
};

export type RolesAndMemberships = {
  Roles: UserRoles;
  MemberTypes: MemberType[];
};

export const meApi = createApi({
  reducerPath: 'meApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'Me', 'Me-Roles'],
  endpoints: (build) => ({
    // Provide separate queries between roles/memberships and me. Even though
    // they have the same endpoint, we don't want to re-render the page on
    // every 'me' update
    getRolesAndMemberships: build.query<RolesAndMemberships, void>({
      query: () => ({
        url: '/users/me',
      }),
      transformResponse: (response: UserInfo) => {
        return {
          Roles: response.Roles || {},
          MemberTypes: response.MemberTypes || [],
        };
      },
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result ? [{ type: 'Me-Roles' }] : [];
      },
    }),
    getMe: build.query({
      query: () => ({
        url: '/users/me',
      }),
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result ? [{ type: 'Me' }] : [];
      },
    }),
    updateMe: build.mutation<UserInfo, UpdateMeRequest>({
      query: (me: UpdateMeRequest) => ({
        url: '/users/me',
        method: 'PUT',
        body: me,
      }),
      invalidatesTags(_, error) {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return [{ type: 'Me' }];
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetRolesAndMembershipsQuery,
  useUpdateMeMutation,
} = meApi;

export function getAssociatedSiteIDs(rm?: RolesAndMemberships): number[] {
  if (!rm) {
    return [];
  }

  const membershipSiteIDs = rm.MemberTypes?.map((mt) => mt.SiteID) || [];
  const roleSiteIDs = rm.Roles?.SiteRoles?.map((r) => r.SiteID) || [];
  return [...membershipSiteIDs, ...roleSiteIDs];
}

export function getRoleSitesIDs(rm?: RolesAndMemberships): number[] {
  if (!rm) {
    return [];
  }
  return rm.Roles?.SiteRoles?.map((r) => r.SiteID) || [];
}
