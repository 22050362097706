import axios from 'axios';
import { createContext, useState } from 'react';
import { Perms } from '../Model/Perm';
import { toApiUrl } from '../Util/Api';
import { LoadingStatus } from './LoadingStatus';

export const PermAddSite = 'add-site';
export const PermEditSite = 'edit-site';

export const PermAddUserToSiteRole = 'add-user-to-site-role';
export const PermRemoveUserFromSiteRole = 'remove-user-from-site-role';

export const PermViewSiteReports = 'view-site-reports';

export const PermAddSiteEventType = 'add-site-event-type';
export const PermEditSiteEventType = 'edit-site-event-type';
export const PermDelSiteEventType = 'delete-site-event-type';

export const PermViewSiteMemberType = 'view-site-member-type';
export const PermEditSiteMemberType = 'edit-site-member-type';
export const PermAddSiteMemberType = 'add-site-member-type';
export const PermDeleteSiteMemberType = 'delete-site-member-type';

export const PermViewSiteMemberTypeMapping = 'view-site-member-type-mapping';
export const PermAddSiteMemberTypeMapping = 'add-site-member-type-mapping';
export const PermEditSiteMemberTypeMapping = 'edit-site-member-type-mapping';
export const PermDeleteSiteMemberTypeMapping =
  'delete-site-member-type-mapping';

export const PermEditSiteCourt = 'edit-site-court';
export const PermDelSiteCourt = 'delete-site-court';
export const PermAddSiteCourt = 'add-site-court';

export const PermEditSiteSlot = 'edit-site-slot';
export const PermAddSiteSlot = 'add-site-slot';
export const PermDelSiteSlot = 'delete-site-slot';

export const PermEditSiteBooking = 'edit-site-booking';
export const PermAddSiteBooking = 'add-site-booking';
export const PermDeleteSiteBooking = 'delete-site-booking';

export const PermViewSiteSlotType = 'view-site-slot-type';
export const PermAddSiteSlotType = 'add-site-slot-type';
export const PermEditSiteSlotType = 'edit-site-slot-type';
export const PermDeleteSiteSlotType = 'delete-site-slot-type';

export const PermViewSiteMemberTypeRule = 'view-site-member-type-rule';
export const PermAddSiteMemberTypeRule = 'add-site-member-type-rule';
export const PermEditSiteMemberTypeRule = 'edit-site-member-type-rule';
export const PermDelSiteMemberTypeRule = 'delete-site-member-type-rule';

export const GlobaAdminPerms = [PermAddSite];

export const SiteAdminPerms = [
  PermEditSite,

  PermAddUserToSiteRole,
  PermRemoveUserFromSiteRole,

  PermAddSiteEventType,
  PermEditSiteEventType,
  PermDelSiteEventType,

  PermViewSiteReports,

  PermViewSiteMemberType,
  PermAddSiteMemberType,
  PermEditSiteMemberType,
  PermDeleteSiteMemberType,

  PermViewSiteMemberTypeMapping,
  PermAddSiteMemberTypeMapping,
  PermEditSiteMemberTypeMapping,
  PermDeleteSiteMemberTypeMapping,

  PermEditSiteCourt,
  PermDelSiteCourt,
  PermAddSiteCourt,

  PermDelSiteSlot,
  PermEditSiteSlot,
  PermAddSiteSlot,

  PermEditSiteBooking,
  PermAddSiteBooking,
  PermDeleteSiteBooking,

  PermViewSiteSlotType,
  PermAddSiteSlotType,
  PermEditSiteSlotType,
  PermDeleteSiteSlotType,

  PermViewSiteMemberTypeRule,
  PermAddSiteMemberTypeRule,
  PermEditSiteMemberTypeRule,
  PermDelSiteMemberTypeRule,
];

export const HasGlobalPerm = (state: PermsState, perm: string): boolean => {
  if (state.loadingStatus !== LoadingStatus.LOADED) {
    return false;
  }

  if (!state.perms) {
    return false;
  }

  return !!state.perms.GlobalPerms.find((p) => p === perm);
};

export const HasAnyGlobalPerm = (state: PermsState): boolean => {
  if (state.loadingStatus !== LoadingStatus.LOADED) {
    return false;
  }

  if (!state.perms) {
    return false;
  }

  return state.perms.GlobalPerms.length > 0;
};

export const HasSitePerm = (
  state: PermsState,
  siteId: number,
  perm: string
): boolean => {
  return HasAnySitePerm(state, siteId, perm);
};

export const HasAnySitePerm = (
  state: PermsState,
  siteId: number,
  ...perm: string[]
): boolean => {
  if (state.loadingStatus !== LoadingStatus.LOADED) {
    return false;
  }

  if (!state.perms) {
    return false;
  }

  for (let i = 0; i < perm.length; i++) {
    for (let j = 0; j < state.perms.SitePerms.length; j++) {
      if (state.perms.SitePerms[j].Name !== perm[i]) {
        continue;
      }
      if (state.perms.SitePerms[j].SiteID === siteId) {
        return true;
      }
    }
  }
  return false;
};

export const HasSiteAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(state, siteId, ...SiteAdminPerms);
};

export const HasSiteCourtAdminPerm = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermAddSiteCourt,
    PermEditSiteCourt,
    PermDelSiteCourt
  );
};

export const HasSiteMemberTypeAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermAddSiteMemberType,
    PermEditSiteMemberType,
    PermDeleteSiteMemberType
  );
};

export const HasSiteMemberTypeMappingAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermAddSiteMemberTypeMapping,
    PermEditSiteMemberTypeMapping,
    PermDeleteSiteMemberTypeMapping
  );
};

export const HasSiteUserRolesAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermAddUserToSiteRole,
    PermRemoveUserFromSiteRole
  );
};

export const HasSiteRoleAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermAddUserToSiteRole,
    PermRemoveUserFromSiteRole
  );
};

export const HasSiteSlotTypeAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermViewSiteSlotType,
    PermAddSiteSlotType,
    PermEditSiteSlotType,
    PermDeleteSiteSlotType
  );
};

export const HasSiteMemberTypeRuleAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermViewSiteMemberTypeRule,
    PermAddSiteMemberTypeRule,
    PermEditSiteMemberTypeRule,
    PermDelSiteMemberTypeRule
  );
};

export const HasSiteReportsAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(state, siteId, PermViewSiteReports);
};

export const HasSiteEventTypeAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermAddSiteEventType,
    PermEditSiteEventType,
    PermDelSiteEventType
  );
};

export const HasSiteSlotAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermAddSiteSlot,
    PermEditSiteSlot,
    PermDelSiteSlot
  );
};

export const HasSiteBookingAdminPerms = (
  state: PermsState,
  siteId: number
): boolean => {
  return HasAnySitePerm(
    state,
    siteId,
    PermEditSiteBooking,
    PermAddSiteBooking,
    PermDeleteSiteBooking
  );
};

export type PermsState = {
  loadingStatus: LoadingStatus;
  perms: Perms;
};

export type PermContextType = {
  perms: PermsState;
  load: () => Promise<LoadingStatus>;
};

const initialState: PermsState = {
  loadingStatus: LoadingStatus.LOADING,
  perms: { SitePerms: [], GlobalPerms: [] },
};

const initialContext: PermContextType = {
  perms: initialState,
  load: async () => {
    return LoadingStatus.LOADING;
  },
};

export const PermsContext = createContext<PermContextType>(initialContext);

export const PermsProvider = (props: any) => {
  const [perms, setPerms] = useState<PermsState>(initialState);

  const loadPerm = async () => {
    try {
      const res = await axios.get<Perms>(toApiUrl('/auth/permissions'));
      setPerms({
        loadingStatus: LoadingStatus.LOADED,
        perms: res.data,
      });
      return LoadingStatus.LOADED;
    } catch (e: any) {
      if (e.response && e.response.status === 401) {
        setPerms({
          loadingStatus: LoadingStatus.UNAUTHORIZED,
          perms: { SitePerms: [], GlobalPerms: [] },
        });

        return LoadingStatus.UNAUTHORIZED;
      }
      if (
        e.response &&
        (e.response.status === 403 || e.response.status === 429)
      ) {
        setPerms({
          loadingStatus: LoadingStatus.FORBIDDEN,
          perms: { SitePerms: [], GlobalPerms: [] },
        });
        return LoadingStatus.FORBIDDEN;
      }

      return LoadingStatus.ERROR;
    }
  };

  const context: PermContextType = {
    perms: perms,
    load: loadPerm,
  };

  return (
    <PermsContext.Provider value={context}>
      {props.children}
    </PermsContext.Provider>
  );
};
