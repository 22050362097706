export interface GameType {
  ID: number;
  Name: string;
  Subtype: string;
  NumPlayers: number;
  Description: string;
  AdminOnly: boolean;
}

export const toShorthand = (gameType: GameType): string => {
  let shorthand = '';

  const typeWords = gameType.Name.split(' ');
  for (let i = 0; i < typeWords.length; i++) {
    let word = typeWords[i];
    shorthand += word.charAt(0);
  }

  if (gameType.Subtype.length === 0) {
    return shorthand;
  }

  shorthand += '-';

  const subtypeWords = gameType.Subtype.split(' ');
  for (let i = 0; i < subtypeWords.length; i++) {
    let word = subtypeWords[i];
    shorthand += word.charAt(0);
  }

  return shorthand;
};

export const ToShorthand = (gameTypes?: GameType[]): string => {
  let shorthand = '';

  if (!gameTypes) {
    return shorthand;
  }

  const gameTypeNames: string[] = [];
  gameTypes.forEach((element) => {
    gameTypeNames.push(toShorthand(element));
  });

  return gameTypeNames.join(' | ');
};

export const EmptyGameType: GameType = {
  ID: 0,
  Name: '',
  Subtype: '',
  NumPlayers: 1,
  AdminOnly: false,
  Description: '',
};

export interface GroupedGameTypes {
  [key: string]: GameType[];
}

export function GroupByName(gameTypes: GameType[]): GroupedGameTypes {
  const grouped: GroupedGameTypes = {};

  gameTypes.forEach((gameType) => {
    if (!gameType.Name) {
      grouped[gameType.ID] = [gameType];
    } else {
      if (grouped[gameType.Name]) {
        grouped[gameType.Name].push(gameType);
      } else {
        grouped[gameType.Name] = [gameType];
      }
    }
  });

  return grouped;
}

export function FindGameTypeById(
  id: number,
  gameTypes: GameType[]
): GameType | undefined {
  return gameTypes.find((gameType) => gameType.ID === id);
}

export function TitleById(id: number, gameTypes: GameType[]): string {
  const gameType = FindGameTypeById(id, gameTypes);

  if (!gameType) {
    return '';
  }

  return gameType.Name + ' - ' + gameType.Subtype;
}
