import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { Event } from '../../Model/Event';
import { UserInfo } from '../../Model/UserInfo';
import { BookingInfo } from './BookingDialog';

type Props = {
  bookingInfo?: BookingInfo;
  event?: Event;
  me?: UserInfo;
};

export const BookingDetails = (props: Props) => {
  const bookingCost = props.bookingInfo?.SelectedGameType.Cost;
  return (
    <Grid spacing={1} container display={'flex'} alignItems={'center'}>
      <Grid item textAlign={'right'} xs={6} fontWeight={'bold'}>
        Player&nbsp;1:
      </Grid>
      <Grid item xs={6}>
        {props.me?.FirstName} {props.me?.LastName}
      </Grid>
      <Grid item textAlign={'right'} xs={6} fontWeight={'bold'}>
        Court:
      </Grid>
      <Grid item xs={6}>
        {props.event?.CourtName}
      </Grid>
      <Grid item textAlign={'right'} xs={6} fontWeight={'bold'}>
        Date:
      </Grid>
      <Grid item xs={6}>
        {DateTime.fromISO(props.event?.Start || '').toFormat('yyyy-MM-dd')}
      </Grid>
      <Grid item textAlign={'right'} xs={6} fontWeight={'bold'}>
        Time:
      </Grid>
      <Grid item xs={6}>
        {DateTime.fromISO(props.event?.Start || '').toFormat('HH:mm')} -{' '}
        {DateTime.fromISO(props.event?.End || '').toFormat('HH:mm')}
      </Grid>
      <Grid item textAlign={'right'} xs={6} fontWeight={'bold'}>
        Cost:
      </Grid>
      <Grid item xs={6}>
        {bookingCost === 0 ? 'Free' : `€${bookingCost}`}
      </Grid>
    </Grid>
  );
};
